const UpVote = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 15V9H13C13.6 9 14 8.7 14 8C14 7.5 13.7 7.2 13.4 6.9L8.2 0.7C7.8 0.3 7.5 0 7 0C6.5 0 6.2 0.3 5.8 0.7L0.6 6.9C0.3 7.2 0 7.5 0 8C0 8.7 0.4 9 1 9H4V15C4 15.6 4.4 16 5 16H9C9.6 16 10 15.6 10 15Z"
        fill="currentColor" />
    </svg>
  );
};

export default UpVote;
