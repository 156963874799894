const DownVote = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 1V7H13C13.6 7 14 7.3 14 8C14 8.5 13.7 8.8 13.4 9.1L8.2 15.3C7.8 15.7 7.5 16 7 16C6.5 16 6.2 15.7 5.8 15.3L0.6 9.1C0.3 8.8 0 8.5 0 8C0 7.3 0.4 7 1 7H4V1C4 0.4 4.4 0 5 0H9C9.6 0 10 0.4 10 1Z"
        fill="currentColor" />
    </svg>
  );
};

export default DownVote;
